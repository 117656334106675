import React from 'react';
import styled from 'styled-components';
import Logo from './sparks.png';

export default function Sparks() {
  return (
    <Wrapper>
      <img src={Logo} alt="Want to spark something unexpected?" />
      <TextContainer>
        <h2>Sparks internship</h2>
        <p>
          We believe that truly great things start with a Spark. Our interns
          work alongside our established creative team, over a 6-week period.
          They get first hand insight into agency life; working on live
          projects, attending brainstorms, presenting in creative team meetings,
          and shadowing our talented copywriters and art directors.
        </p>
        <p>
          If you're a bright young mind interested in the creative world,
          contact us to take part in the next wave.
        </p>
        <Button href="mailto:becky.law@cherrythinking.com">Get in touch</Button>
      </TextContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #76569c;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    img {
      width: 768px;
      height: 569px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding-top: 135px;
    padding-bottom: 90px;
    justify-content: center;

    img {
      margin: unset;
      width: 560px;
      height: 414px;
    }
  }

  @media screen and (min-width: 1440px) {
    img {
      width: 787px;
      height: 582px;
    }
  }
`;

const TextContainer = styled.div`
  color: #ffffff;
  padding-bottom: 64px;
  padding-inline: 18px;

  h2,
  p {
    margin-bottom: 24px;
  }

  p:last-child {
    margin-bottom: 32px;
  }

  h2 {
    font-size: 32px;
    font-weight: 400;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 66px;
    padding-inline: 0px;
    max-width: 533px;
    margin-top: 40px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1024px) {
    max-width: 490px;
    margin-inline: unset;
    flex: 1;
    margin-top: 75px;
    margin-left: 28px;

    h2 {
      font-size: 48px;
    }

    p {
      max-width: 475px;
    }
  }
  @media screen and (min-width: 1440px) {
    margin: 121px 0px 0px 28px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    margin-top: 0px;

    p {
      font-size: 1.6em;
      line-height: 1.5;
      max-width: 100%;
    }
  }
`;

const Button = styled.a`
  text-decoration: none;
  display: block;
  width: 180px;
  height: 45px;
  font-weight: 500;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  color: #91004b;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 22px;
`;
